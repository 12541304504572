import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, LOCALE_ID } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiService } from './services/api.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationService } from './services/authentication.service';
import '@angular/common/locales/global/is';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { DatePipe, registerLocaleData } from '@angular/common';
import { FileSaverModule } from 'ngx-filesaver';
import { FileUploadModule } from 'primeng/fileupload';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CoreModule } from './@core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import localeIS from '@angular/common/locales/is';
registerLocaleData(localeIS);

@NgModule({
  imports: [
    OverlayPanelModule,
    BrowserModule,
    BrowserAnimationsModule,
    //ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    CoreModule,
    SidebarModule,
    //FileSaverModule,
    //FileUploadModule,
    AppRoutingModule,
    NgxPermissionsModule.forRoot(),
  ],
  // providers: [
  //   { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  //   { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  //   {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: NoCacheHeadersInterceptor,
  //     multi: true,
  //   },
  //   { provide: ErrorHandler, useClass: GlobalErrorHandler },
  //   ApiService,
  //   AuthenticationService,
  //   MessageService,
  //   ConfirmationService,
  //   DatePipe,
  // ],
  declarations: [AppComponent],
  providers: [
    ApiService,
    AuthenticationService,
    MessageService,
    ConfirmationService,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'is-IS' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // constructor(
  //   private readonly router: Router,
  // ) {
  //   router.events
  //     .subscribe(console.log)
  // }
}
